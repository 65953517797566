@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: inherit; 
  border-bottom: none; /* Remove the bottom border */
  position: relative; /* Make the header positioned relatively */
  z-index: 1000; /* Ensure the header is on top */
}

.logo img {
  width: 230px; /* Adjust the size of the logo */
  height: 50px;
  padding-left: 20px;
  padding-top: 10px;
}

.invisibleDiv {
  padding-right: 100px;
}

.navLinks {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 40px; /* Adjust gap between links */
}

.navLink {
  text-decoration: none;
  color: black;
  font-size: 16px; /* Adjust font size */
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.navLink.active {
  /* background: linear-gradient(to right, #FF86D6, #477BFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: blue;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.hamburgerLine {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 3px 0;
}

.closeIcon {
  font-size: 25px;
  font-weight: bold;
}

.mainContent {
  padding: 20px;
  min-height: 80vh; /* Ensure the main content takes up most of the viewport height */
}

.mainContent {
  padding-left: 60px;
  min-height: 80vh;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftContent {
  width: 50%;
  margin-bottom: 10px;
}

.heading {
  margin-top: 80px;
  font-size: 32px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.gradientText {
  background: linear-gradient(to right, #FF86D6, #477BFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paragraph {
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 50px;
  margin-top: 30px;
}

.rightContent {
  width: 50%;
  text-align: right;
}

.shopImage {
  height: 500px; /* Adjust to match the height of the left content */
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.moreButton {
  width: 230px;
  height: 80px;
  background: linear-gradient(to right, #FF86D6, #477BFF);
  border: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}

.moreButton:hover {
  opacity: 0.8;
}

.servicesSection {
  padding: 40px 20px;
  background-color: white; /* Optional: Add background color to distinguish the section */
  text-align: center;
}

.servicesParagraph {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 50px;
}

.servicesContainer {
  display: flex;
  flex-direction: column; /* Stack the containers vertically */
  gap: 40px;
  padding: 0 20px;
}

.serviceBox {
  background-color: white;
  border-radius: 40px; /* Increase corner radius */
  box-shadow: 4px 7px 11.5px rgba(0, 0, 0, 0.25); /* Add shadow */
  padding: 0px 60px;
  display: flex;
  align-items: center;
  height: 350px;
}

.serviceImage {
  width: 50%; /* Set image width to 40% */
  height: 90%; /* Fit height within the container */
  object-fit:contain; /* Ensure the complete image is visible */
  /* border-rad ius: 40px; */
}

.serviceContent {
  width: 60%; /* Set content width to 60% */
  text-align: left;
}

.serviceHeading {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
  color: black;
}

.serviceText {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

/* New About Section Styles */
.aboutSection {
  padding: 40px 20px;
  background-color: white; /* Optional: Add background color to distinguish the section */
  text-align: center;
}

.aboutParagraph {
  font-size: 16px;
  padding:20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto; /* Center the paragraph */
}

.contactSection {
  padding: 40px 20px;
  background-color: white; /* Optional: Add background color to distinguish the section */
  text-align: center;
}

.contactParagraph {
  font-size: 16px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto; /* Center the paragraph */
  margin-bottom: 30px;
}

.contactForm {
  width: 60%;
  margin-top: 30px;
  /* margin: 0 auto; */
  text-align: left;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.formGroup {
  flex: 1;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}


.formGroup input[type="text"],
.formGroup input[type="email"],
.formGroup input[type="tel"],
.formGroup textarea {
  width: calc(100% - 40px);
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

.formGroup textarea {
  height:100px;
}

.submitButton {
  width: 250px;
  height: 50px;
  background:  linear-gradient(to right, #FF86D6, #477BFF);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.submitButton:hover {
  opacity: 0.8;
  font-weight: bold;
}
.contactWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;
}

.contactForm {
  width: 60%;
  margin-right: 20px; /* Add some space between the form and details */
}

.contactDetails {
  width: 40%;
  margin-top: 50px; /* Adjust this value as needed to move the content down */
  text-align: left;
  margin-left: 10%;
}

.contactInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.locationIcon img,
.whatsappIcon img,
.instagramIcon img {
  width: 24px; /* Use the width and height defined in CSS */
  height: 24px;
  margin-right: 10px; /* Add margin to the right to space out the content */
}


.contactInfo p,
.contactInfo a {
  margin: 0;
}

.contactInfo a {
  color: inherit;
  text-decoration: none;
}

.contactInfo a:hover {
  background: linear-gradient(to right, #FF86D6, #477BFF); /* Match the gradient color */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.servicesHeading, .aboutHeading, .contactHeading {
  font-size: 48px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  background: linear-gradient(to right, #FF86D6, #477BFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Ensure it wraps the content correctly */
  text-align: center; /* Center align text */
  margin-bottom: 40px;
}

.footer {
  padding: 20px;
}
.footerText {
  font-family: 'Poppins', sans-serif;
  font-size: 18px; /* Adjusted font size */
  text-align: center;
  font-weight: 500;
  color:#6f6f6f;
}



/* Responsive Styles */
@media (max-width: 768px) {
  .navLinks {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Position below the header */
    left: 0;
    background-color: #fff; /* Match the background color of the header */
    padding: 20px; /* Add top padding for spacing */
  }

  .invisibleDiv {
    display: none;
  }

  .navLinks.active {
    display: flex;
  }

  .navLink {
    padding: 10px 20px; /* Add padding for better spacing */
    margin: 5px 0; /* Add margin for better spacing */
    width: 100%; /* Ensure full width for better spacing */
    text-align: left; /* Align text to the left */
  }

  .hamburger {
    display: flex;
  }
  
  .rightContent {
    display: none;
  }
  
  .servicesContainer {
    gap: 20px;
  }

  .serviceBox {
    flex-direction: column; /* Stack image and text vertically */
    height: auto;
  }
  .servicesHeading {
    font-size: 36px;
  }

  .serviceImage, .serviceContent {
    width: 100%; /* Set both to full width in mobile view */
    padding: 20px;
  }
  .serviceHeading {
    font-size: 24px;
  }
  .serviceText {
    font-size: 16px;
  }

  .serviceImage {
    margin-bottom: 20px; /* Add margin below the image */
  }
  .aboutHeading {
    font-size: 36px;
  }
  .contactParagraph {
    font-size: 16px;
  }
  .contactInfo {
    /* padding: 20px; */
    font-size:14px;
  }
  .contactForm {
    width: 80%;
    padding: 20px;
  }
  .contactWrapper{
    display:block;
  }
  .formGroup {
    margin: 0px 10px;
  }
}
