@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.WA_Chat_Widget * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.WA_Chat_Widget .WA_FloatingButton {
  position: fixed;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.185);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  z-index: 1000;
}

.WA_Chat_Widget .WA_FloatingButton svg {
  width: 40px;
  height: 40px;
  fill: #4fce5d;
}

.WA_Chat_Widget[data-position^="top-"] .WA_FloatingButton {
  top: 20px;
}

.WA_Chat_Widget[data-position^="bottom-"] .WA_FloatingButton {
  bottom: 50px;
}

.WA_Chat_Widget[data-position$="-left"] .WA_FloatingButton {
  left: 20px;
}

.WA_Chat_Widget[data-position$="-right"] .WA_FloatingButton {
  right: 30px;
}

.WA_Chat_Widget[data-position$="-center"] .WA_FloatingButton {
  left: 50%;
  transform: translateX(-50%);
}

.WA_Chat_Widget[data-position^="top-"] .WA_ChatBox {
  top: 110px;
}

.WA_Chat_Widget[data-position^="bottom-"] .WA_ChatBox {
  bottom: 110px;
}

.WA_Chat_Widget[data-position$="-left"] .WA_ChatBox {
  left: 30px;
}

.WA_Chat_Widget[data-position$="-right"] .WA_ChatBox {
  right: 30px;
}

.WA_ChatBox {
  position: fixed;
  z-index: 9999;
  width: 100%;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.185);
  border-radius: 10px;
  display: none;
  flex-direction: column;
}

.WA_ChatBox .WA_ChatBox_Header {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ededed;
}

.WA_ChatBox .WA_ChatBox_Header .avatarBox {
  flex: 0 0 50px;
  margin-right: 10px;
}

.WA_ChatBox .WA_ChatBox_Header .avatarBox img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.WA_ChatBox .WA_ChatBox_Header .infoBox {
  flex: 1;
}

.WA_ChatBox .WA_ChatBox_Header .infoBox .name {
  font-size: 16px;
  font-weight: 500;
}

.WA_ChatBox .WA_ChatBox_Header .infoBox .answer_time {
  font-size: 12px;
  color: #888;
}

.WA_ChatBox .WA_ChatBox_Header .WA_Close {
  background: none;
  border: none;
  cursor: pointer;
}

.WA_ChatBox .WA_ChatBox_Header .WA_Close svg {
  width: 20px;
  height: 20px;
  fill: #555;
}

.WA_ChatBox .WA_ChatBox_Body {
  flex: 1;
  padding: 10px;
}

.WA_ChatBox .WA_ChatBox_Body .message {
  margin-bottom: 10px;
}

.WA_ChatBox .WA_ChatBox_Body .message .username {
  font-weight: 500;
}

.WA_ChatBox .WA_ChatBox_Body .message .message_content {
  margin-top: 5px;
}

.WA_ChatBox .WA_ChatBox_Footer {
  padding: 10px;
  background: #ededed;
  text-align: center;
}

.WA_ChatBox .WA_ChatBox_Footer .btn {
  display: inline-block;
  padding: 10px 20px;
  background: #25d366;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
}

.WA_ChatBox .WA_ChatBox_Footer .btn:hover {
  background: #22bb5a;
}

@media (max-width: 768px) {

  .WA_Chat_Widget[data-position^="bottom-"] .WA_FloatingButton {
    bottom: 80px;
  }
  .WA_Chat_Widget[data-position$="-right"] .WA_FloatingButton {
    right: 50px;
  }

}