@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: inherit;
    border-bottom: none;
    position: relative;
    z-index: 1000;
}

.logo img {
    width: 230px;
    height: 50px;
    padding-left: 20px;
    padding-top:10px;
}

.invisibleDiv {
    padding-right: 100px;
}

.navLinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 40px;
}

.navLink {
    text-decoration: none;
    color: black;
    font-size: 16px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}


.navLink.active {
    /* background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color:blue;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}

.hamburgerLine {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
}


.closeIcon {
    font-size: 25px;
    font-weight: bold;
}

.headSection {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
}

.topHeader {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    text-align: center;
    margin-bottom: 40px;
}

.topParagraph {
    font-size: 16px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
}
.bodySection {
    padding: 40px 20px;
    background-color: white;
    /* text-align: center; */
}
.list {
    font-size: 28px;
    padding: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
}

.galleryGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 60px;
}

.galleryItem {
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* This creates a square aspect ratio */
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.galleryItem img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
}

.popupImage {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 10px;
}

.closePopup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.line {
    border: none;
    border-top: 2px solid #eee;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.lang {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
}
.langtext {
    font-size: 24px;
    padding: 20px;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    color: black;
    border: 2px solid #FF86D6;
    border-radius: 30px;
}
.langtext:hover {
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.footer {
    padding: 20px;
}
.footerText {
    font-family: 'Poppins', sans-serif;
    font-size: 18px; /* Adjusted font size */
    text-align: center;
    font-weight: 500;
    color:#6f6f6f;
}
@media (max-width: 768px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }

    .navLinks.active {
        display: flex;
    }

    .navLink {
        padding: 10px 20px;
        margin: 5px 0;
        width: 100%;
        text-align: left;
    }

    .hamburger {
        display: flex;
    }

    .invisibleDiv {
        display: none;
    }

    .topHeader {
        font-size: 28px;
        padding: 0px 40px;
    }

    .topParagraph {
        font-size: 14px;
        padding: 0px 40px;
    }
    .bodySection {
        padding: 20px 40px;
    }
    .list {
        font-size: 18px;
        padding: 30px 20px;
        text-align: left;
    }
    .line {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .list2 {
        font-size:14px;
        padding: 10px 40px;
    }
    .galleryGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 40px;
    }
}
