@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.header {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: inherit;
    border-bottom: none;
    position: relative;
    z-index: 1000;
}

.logo img {
    width: 230px;
    height: 50px;
    padding-left: 20px;
    padding-top: 10px;
}

.invisibleDiv {
    padding-right: 100px;
}

.navLinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 40px;
}

.navLink {
    text-decoration: none;
    color: black;
    font-size: 16px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.navLink.active {
    /* background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color:blue;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}

.hamburgerLine {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
}

.closeIcon {
    font-size: 25px;
    font-weight: bold;
}

.mainContent {
    padding: 20px;
    min-height: 80vh;
}

.headSection {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
}

.topHeader {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    text-align: center;
    margin-bottom: 40px;
}

.topParagraph {
    font-size: 16px;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
}

.projectsSection {
    padding: 0px 20px;
    background-color: white;
}

.projectContainer {
    margin: 10px 0px;
    padding: 60px;
    background-color: #fff;
}

.line {
    border: none;
    border-top: 2px solid #eee;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.projectContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0;
    margin-left: 20px;
    margin-right: 20px;
}

.projectText {
    width: 60%;
    text-align: left;
}

.projectHeading {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 10px;
}

.projectParagraph {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.6;
}

.projPara2 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    line-height: 1.6;
}

.projPara3 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.6;
}

.enquiryButton {
    width: 180px;
    height: 60px;
    background: linear-gradient(to right, #FF86D6, #477BFF);
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.enquiryButton:hover {
    opacity: 0.8;
}

.whatsappIcon {
    margin-right: 20px; /* Adjust the margin as per your preference */
}

.projectImage {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.managementImage {
    width: 70%;
    height: auto;
    object-fit: cover;
}

.footer {
    padding: 20px;
}

.footerText {
    font-family: 'Poppins', sans-serif;
    font-size: 18px; /* Adjusted font size */
    text-align: center;
    font-weight: 500;
    color: #6f6f6f;
}

@media (max-width: 768px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }

    .navLinks.active {
        display: flex;
    }

    .navLink {
        padding: 10px 20px;
        margin: 5px 0;
        width: 100%;
        text-align: left;
    }

    .hamburger {
        display: flex;
    }

    .invisibleDiv {
        display: none;
    }

    .projectContent {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .projectText {
        width: 100%;
        text-align: center;
    }

    .projectImage {
        width: 100%;
        margin-top: 20px;
    }

    .topHeader {
        font-size: 28px;
    }

    .topParagraph {
        font-size: 14px;
        padding: 10px;
    }

    .projectHeading {
        font-size: 24px;
    }

    .projectParagraph,
    .projPara2,
    .projPara3 {
        font-size: 14px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }

    .navLinks.active {
        display: flex;
    }

    .navLink {
        padding: 10px 20px;
        margin: 5px 0;
        width: 100%;
        text-align: left;
    }

    .hamburger {
        display: flex;
    }

    .invisibleDiv {
        display: none;
    }
}
