@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: inherit;
    border-bottom: none;
    position: relative;
    z-index: 1000;
}

.logo img {
    width: 230px;
    height: 50px;
    padding-left: 20px;
    padding-top:10px;
}

.invisibleDiv {
    padding-right: 100px;
}

.navLinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 40px;
}

.navLink {
    text-decoration: none;
    color: black;
    font-size: 16px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}


.navLink.active {
    /* background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color:blue;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}

.hamburgerLine {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
}


.closeIcon {
    font-size: 25px;
    font-weight: bold;
}

.headSection {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
}

.topHeader {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    text-align: center;
    margin-bottom: 40px;
}

.topParagraph {
    font-size: 16px;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
}

.lang {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
}
.langtext {
    font-size: 24px;
    padding: 20px;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    color: black;
    border: 2px solid #FF86D6;
    border-radius: 30px;
}
.langtext:hover {
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.systemSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px;
    justify-content: center;
    padding: 20px;

}

.container {
    display: flex;
    margin:auto;
    flex-direction: column;
    background-color: white;
    border-radius: 40px;
    box-shadow: 4px 7px 11.5px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 400px; /* Adjusted width */
    height: 400px; /* Adjusted height */
    margin-bottom: 80px;
}

.imageContainer {
    height: 80%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.textContainer {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f0f0f0; */
}

.text {
    font-family: 'Poppins', sans-serif;
    font-size: 18px; /* Adjusted font size */
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    color:black;
}
.text:hover {
    background: linear-gradient(to right, #FF86D6, #477BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
}

.footer {
    padding: 20px;
}
.footerText {
    font-family: 'Poppins', sans-serif;
    font-size: 18px; /* Adjusted font size */
    text-align: center;
    font-weight: 500;
    color:#6f6f6f;
}

@media (max-width: 768px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }

    .navLinks.active {
        display: flex;
    }

    .navLink {
        padding: 10px 20px;
        margin: 5px 0;
        width: 100%;
        text-align: left;
    }

    .hamburger {
        display: flex;
    }

    .invisibleDiv {
        display: none;
    }

    .topHeader {
        font-size: 28px;
        padding: 0px 30px;
    }

    .topParagraph {
        font-size: 14px;
        padding: 0px 30px;
    }

    .systemSection {
        grid-template-columns: 1fr; /* 1 column for mobile view */
    }

    .container {
        width: 80%; /* Adjusted width for mobile view */
        height: auto; /* Allow height to adjust based on content */
        margin: auto; 
    }

    .imageContainer {
        height: 70%; /* Adjusted height for mobile view */
    }

    .textContainer {
        height: 30%;
        padding:30px;
        /* width: 100%; */
    }

    .text {
        font-size: 16px; /* Adjusted font size for mobile view */
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }

    .navLinks.active {
        display: flex;
    }

    .navLink {
        padding: 10px 20px;
        margin: 5px 0;
        width: 100%;
        text-align: left;
    }

    .hamburger {
        display: flex;
    }

    .invisibleDiv {
        display: none;
    }

    .topHeader {
        font-size: 28px;
        padding: 10px 30px;
    }

    .topParagraph {
        font-size: 14px;
        padding: 0px 30px;
    }

    .systemSection {
        grid-template-columns: 1fr; /* 1 column for mobile view */
    }

    .container {
        width: 70%; /* Adjusted width for mobile view */
        height: auto; /* Allow height to adjust based on content */
        margin: auto; 
    }

    .imageContainer {
        height: 60%; /* Adjusted height for mobile view */
    }

    .textContainer {
        height: 30%;
        padding:30px;
        /* width: 100%; */
    }

    .text {
        font-size: 16px; /* Adjusted font size for mobile view */
    }
}

